import React, { useState, useEffect } from "react";
import MatchCard from "./MatchCard";
import "./Partidos.css";
import axios from "axios";
import { format, startOfWeek, addDays, parse, isValid } from "date-fns";

const DATE_FORMAT = "ddMMMyyyy";

const Partidos = ({ leagueFilter = null, themeColor }) => {
  const [gamesByDate, setGamesByDate] = useState({});
  const [currentDate, setCurrentDate] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dateLoading, setDateLoading] = useState(false);

  // Set initial Monday or current date based on filter
  useEffect(() => {
    const today = new Date();
    const initialDate = leagueFilter
      ? startOfWeek(today, { weekStartsOn: 1 })
      : today;
    const formattedDate = format(initialDate, DATE_FORMAT).toUpperCase();
    setCurrentDate(formattedDate);
  }, [leagueFilter]);

  useEffect(() => {
    const fetchGames = async () => {
      setDateLoading(true); // Set date-specific loading
      try {
        let endpoint;
        if (leagueFilter) {
          endpoint = `https://canutillofut7.com/api/leagues/${leagueFilter}/games/week/${currentDate}`;
        } else {
          const weekMonday = format(
            startOfWeek(parse(currentDate, DATE_FORMAT, new Date()), {
              weekStartsOn: 1,
            }),
            DATE_FORMAT
          ).toUpperCase();
          endpoint = `https://canutillofut7.com/api/games/day/${weekMonday}`;
        }

        const response = await axios.get(endpoint);
        if (!leagueFilter) {
          setGamesByDate((prev) => ({ ...prev, ...response.data }));
        } else {
          setGamesByDate((prev) => ({ ...prev, [currentDate]: response.data }));
        }
        setLoading(false); // Initial loading finished
      } catch (error) {
        console.error("Error loading games data:", error);
        setError("Failed to load games data.");
      } finally {
        setDateLoading(false); // Reset date-specific loading
      }
    };

    if (currentDate) {
      fetchGames();
    }
  }, [leagueFilter, currentDate]);

  const handlePreviousPeriod = () => {
    const previousDate = addDays(
      parse(currentDate, DATE_FORMAT, new Date()),
      leagueFilter ? -7 : -1
    );
    const formattedPreviousDate = isValid(previousDate)
      ? format(previousDate, DATE_FORMAT).toUpperCase()
      : currentDate;
    setCurrentDate(formattedPreviousDate);
  };

  const handleNextPeriod = () => {
    const nextDate = addDays(
      parse(currentDate, DATE_FORMAT, new Date()),
      leagueFilter ? 7 : 1
    );
    const formattedNextDate = isValid(nextDate)
      ? format(nextDate, DATE_FORMAT).toUpperCase()
      : currentDate;
    setCurrentDate(formattedNextDate);
  };

  if (loading) return <div>Loading games...</div>;
  if (error) return <div>{error}</div>;

  const gamesForCurrentDate = gamesByDate[currentDate] || [];

  return (
    <div className="matchcard-container">
      <div className="period-navigation">
        <button onClick={handlePreviousPeriod}>Anterior</button>
        <span>
          {leagueFilter
            ? `Semana: ${
                isValid(parse(currentDate, DATE_FORMAT, new Date()))
                  ? format(parse(currentDate, DATE_FORMAT, new Date()), "MMM d")
                  : "Invalid Date"
              }`
            : `${
                isValid(parse(currentDate, DATE_FORMAT, new Date()))
                  ? format(parse(currentDate, DATE_FORMAT, new Date()), "MMM d")
                  : "Invalid Date"
              }`}
        </span>
        <button onClick={handleNextPeriod}>Proximo</button>
      </div>

      {dateLoading && gamesForCurrentDate.length > 0 ? (
        <div>Loading new date...</div>
      ) : gamesForCurrentDate.length > 0 ? (
        gamesForCurrentDate.map((game) => (
          <MatchCard
            key={game.game_id}
            team1={game.team1_name}
            team2={game.team2_name}
            time={game.game_time}
            date={game.game_date}
            status={
              game.team1_score !== null && game.team2_score !== null
                ? "played"
                : "unplayed"
            }
            team1Score={game.team1_score}
            team2Score={game.team2_score}
            league={leagueFilter || game.league_id}
            displayLeague={!leagueFilter}
            themeColor={leagueFilter ? themeColor : undefined}
          />
        ))
      ) : (
        <p>No hay partidos.</p>
      )}
    </div>
  );
};

export default Partidos;
